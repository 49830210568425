import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { ROUTER_ERROR_PAGE_NAME } from './constants';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: (to) => ({
      name: ROUTER_ERROR_PAGE_NAME,
      params: {
        pathMatch: to.path,
      },
      query: to.query,
    }),
    component: () => import('@/views/authentication/index.vue'),
    children: [
      {
        path: '/authorize',
        component: () => import('@/views/authentication/authorize.vue'),
      },
      {
        path: '/signin',
        component: () => import('@/views/authentication/signin.vue'),
      },
      {
        path: '/login/callback',
        component: () => import('@/views/authentication/social-callback.vue'),
      },
      {
        path: '/logout',
        component: () => import('@/views/authentication/logout.vue'),
      },

      /**
       * begin::strategy(local-database)
       */
      {
        path: '/signup',
        component: () =>
          import('@/views/authentication/local-database/signup.vue'),
      },
      {
        path: '/forgot-password',
        component: () =>
          import('@/views/authentication/local-database/forgot-password.vue'),
      },
      {
        path: '/reset-password',
        component: () =>
          import('@/views/authentication/local-database/reset-password.vue'),
      },

      /**
       * begin::Resource
       */
      {
        path: '/resource/link',
        component: () => import('@/views/authentication/resource/link.vue'),
      },
    ],
  },
  {
    path: '*',
    name: ROUTER_ERROR_PAGE_NAME,
    component: () => import('@/views/errors/server-error.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
