import { AxiosRequestConfig } from 'axios';
import { HttpService } from '../http';
import { Tenant } from './tenant.interface';
import { TenantObservable } from './tenant.observable';

export class TenantService {
  static async init(): Promise<void> {
    try {
      await this.get();
    } catch (e) {
      console.warn('Failed to get tenant', e);
    }
  }

  static async get(options?: AxiosRequestConfig): Promise<Tenant> {
    const { data } = await HttpService.request({
      url: '/tenant',
      withCredentials: true,
      ...(options || {}),
    });

    // Set tenant observable
    this.setObservable(data);

    return data;
  }

  private static async setObservable(tenant: Tenant): Promise<void> {
    TenantObservable.name = tenant.name;
    TenantObservable.description =
      tenant.description || TenantObservable.description;

    TenantObservable.support_email = tenant.support_email;
    TenantObservable.support_url = tenant.support_url;
  }
}
