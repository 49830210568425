import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import MetronicVue from '@vedicium/metronic-vue';
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas as FontAwesomeSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import { TenantService, TenantObservable } from './libs/tenant';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(MetronicVue);

FontAwesomeLibrary.add(FontAwesomeSolid);
Vue.component('font-awesome-icon', FontAwesomeIcon);

async function bootstrap() {
  await TenantService.init();
  document.title = TenantObservable.name;

  return new Vue({
    router,
    render: (h) => h(App),
  }).$mount('#app');
}

bootstrap();
